<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>听书管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/cate' }">类型</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info margin-l-sm">
      <el-form ref="form" :model="form" :rules="rules">

        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" class="input"></el-input>
        </el-form-item>

        <el-form-item label="状态：">
          <el-switch v-model="form.status" :active-value="1" :inactive-value="2" @change="setStatus">
          </el-switch>
          <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "template-add",
  data () {
    return {
      statusTxt: '启用',
      loading: false,
      form: {
        status: 1,
        name: "",
      },

      rules: {
        name: [
          { required: true, message: '名称必须', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    ...mapActions('hearBook', ['setCateAdd']),

    onSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.add()
        } else {
          return false
        }
      });
    },
    async add () {
      let _this = this
      this.loading = true
      await this.setCateAdd(this.form).then(res => {
        if (res.ret == 0) {
          _this.$message.success('新增成功！')
          _this.$router.push('/cate')
        }
      })
      this.loading = false
    },
    setStatus (status) {
      if (this.form.status === 1) {
        this.statusTxt = '启用'
      } else {
        this.statusTxt = '禁用'
      }
      this.form.status = status
    },
  }
}
</script>

<style scoped>
.margin-l-sm {
  margin-left: 1.25rem;
}
</style>
